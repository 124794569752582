import { ProjectData } from '@/Components/Home/HotProjects';
import AreaIconSvg from '@/Components/SVG/AreaIconSvg';
import DoorIconSvg from '@/Components/SVG/DoorIconSvg';
import HomeIconSvg from '@/Components/SVG/HomeIconSvg';
import PhoneIconSvg from '@/Components/SVG/PhoneIconSvg';
import WhatsappIconSvg from '@/Components/SVG/WhatsappIconSvg';
import styles from '@/styles/Cards/Project/hotproject.module.scss';
import {
  CalculatePercentChange,
  NumberFormatFixed,
  convertNumberToPrice,
  slugify,
} from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
interface hotProjectInterface {
  data: ProjectData;
}
const HotProjectCard = ({ data }: hotProjectInterface) => {
  const [minROI, setminROI] = useState<null | number>(null);
  const [maxROI, setmaxROI] = useState<null | number>(null);
  const [percentChange, setpercentChange] = useState<null | string>(null);
  const [opentool, setOpentool] = useState(false);

  const handleClosetool = () => {
    setOpentool(false);
  };

  const router = useRouter();
  const handleOpentool = () => {
    setOpentool(true);
  };
  useEffect(() => {
    if (data.rentalMinPrice && data.rentalMaxPrice) {
      setminROI((data.rentalMinPrice / data.minPrice) * 100);
      setmaxROI((data.rentalMaxPrice / data.maxPrice) * 100);
    }
    if (data) {
      setpercentChange(
        CalculatePercentChange(
          data.prevYearTransactions,
          data.totalTransactions
        )
      );
    }

    return () => {
      setminROI(null);
      setmaxROI(null);
      setpercentChange(null);
    };
  }, [data]);

  const agentProfile = data.users || {
    firstName: 'Ali',
    lastName: 'Hassan',
    phoneNumber: '+971503683775',
    RealEstateName: 'Aaj Property',
    email: 'Alihassan@aajproperty.com',
    profilePic:
      'https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/5005061.webp',
  };
  const { isMobile } = useContext(MobileContext);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContainer_top}>
        <div
          className={styles.cardContainer_top_left}
          onClick={() => {
            Cookies.set('project-user', JSON.stringify(data.users));
            router.push(
              `/dubai/projects/${slugify(data.project)}/${slugify(
                data.bed
              )}/${slugify(data.Type)}`
            );
          }}
        >
          <Image
            src={data?.overview?.projectImage[0]}
            placeholder={`data:image/svg+xml;base64,${ImageToBase64(
              LoaderShimmer(700, 475)
            )}`}
            alt={data.project || 'project'}
            width={700}
            height={600}
          />

          <section className={styles.cardContainer_top_left_overlay}>
            {isMobile && (
              <div className={styles.cardContainer_top_left_overlay_numbers}>
                <div
                  className={styles.cardContainer_top_left_overlay_numbers_top}
                >
                  <span> {data.bed}</span>
                  <aside></aside>
                  <span> {data.Type}</span>
                  <aside></aside>
                  <span>
                    {' '}
                    {data?.averageSize &&
                      NumberFormatFixed(data?.averageSize)}{' '}
                    Sqft
                  </span>
                </div>
                <div
                  className={
                    styles.cardContainer_top_left_overlay_numbers_bottom
                  }
                >
                  <span>View Property Detail</span>
                  <div>
                    <ArrowCircleRightIcon style={{ color: '#d12d34' }} />{' '}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.cardContainer_top_left_overlay_developer}>
              <Image
                src={data?.overview?.developerImage}
                alt={data.Developer ? data.Developer[0] : ''}
                width={100}
                height={100}
              />
              <aside>
                <span>{data.Developer}</span>
                <p>
                  (Rank No. {data?.rankdeveloper?.rank} in Secondary Market)
                </p>
              </aside>
            </div>
          </section>
        </div>
        <div className={styles.cardContainer_top_right}>
          <div className={styles.cardContainer_top_right_item}>
            <span>Price Range</span>
            <p>
              {convertNumberToPrice(data.minPrice)} -{' '}
              {convertNumberToPrice(data.maxPrice)}
            </p>
          </div>
          {data.rentalMinPrice && (
            <div className={styles.cardContainer_top_right_item}>
              <span>Rental Range</span>
              <p>
                {convertNumberToPrice(data.rentalMinPrice)} -{' '}
                {convertNumberToPrice(data.rentalMaxPrice)} Yearly
              </p>
            </div>
          )}
          {minROI && maxROI && (
            <div className={styles.cardContainer_top_right_roi}>
              <span>
                ROI Range {minROI} - {maxROI}%
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.cardContainer_bottom}>
        <div className={styles.cardContainer_bottom_left}>
          <header>
            <h2>{data.project}</h2>
            <p>{data.location}</p>
          </header>

          <div className={styles.cardContainer_bottom_left_numbers}>
            <span>
              {' '}
              <DoorIconSvg color="#d12d34" width={18} height={18} /> {data.bed}
            </span>
            <span>
              {' '}
              <HomeIconSvg color="#d12d34" width={18} height={18} />
              {data.Type}
            </span>
            <span>
              {' '}
              <AreaIconSvg color="#d12d34" width={18} height={18} />
              {data?.averageSize && NumberFormatFixed(data?.averageSize)} Sqft
            </span>
          </div>
          {isMobile && (
            <div className={styles.cardContainer_bottom_left_counts}>
              <div className={styles.cardContainer_bottom_left_counts_pr}>
                <span>Price Range</span>
                <p>
                  {convertNumberToPrice(data.minPrice)} -{' '}
                  {convertNumberToPrice(data.maxPrice)}
                </p>
              </div>
              {data.rentalMinPrice && <section></section>}
              {data.rentalMinPrice && (
                <div className={styles.cardContainer_bottom_left_counts_pr}>
                  <span>Rental Range</span>
                  <p>
                    {convertNumberToPrice(data.rentalMinPrice)} -{' '}
                    {convertNumberToPrice(data.rentalMaxPrice)} Yearly
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.cardContainer_bottom_right}>
          {isMobile && minROI && maxROI && (
            <button className={styles.cardContainer_bottom_right_roi}>
              ROI Range {minROI} - {maxROI}%
            </button>
          )}
          {percentChange && (
            <button className={styles.cardContainer_bottom_right_item}>
              Market {percentChange.includes('+') ? 'increase' : 'decrease'}:{' '}
              {percentChange} Txn 1Y
            </button>
          )}

          <button
            className={styles.cardContainer_bottom_right_item}
            onClick={() =>
              router.push(
                `/dubai/trends/${slugify(data.location)}/${slugify(
                  data.project
                )}/${slugify(
                  String(data.bed).replace('B/R', 'bedroom')
                )}-prices`
              )
            }
          >
            Explore Sales Chart &History
          </button>
          <button
            className={styles.cardContainer_bottom_right_item}
            onClick={() =>
              router.push(
                `/projects/variations?keyword=${data.project}&bed=${String(
                  data.bed
                )}`
              )
            }
          >
            Compare Prices
          </button>
        </div>
      </div>
      <div className={styles.cardContainer_agent}>
        <Tooltip
          open={opentool}
          onOpen={handleOpentool}
          onClose={handleClosetool}
          style={{ background: '#d12d34' }}
          componentsProps={{
            tooltip: {
              sx: {
                borderRadius: '10px',
                background: 'linear-gradient(180deg, #D12D34 0%, #FF4D55 100%)',

                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                padding: '8px 16px',
              },
            },
            arrow: {
              sx: {
                color: '#FF4D55',
              },
            },
          }}
          title={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  color: ' #FFF',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '137.9%',
                }}
              >
                Hi, I’m {agentProfile?.firstName} <br /> Looking for Property in
                this area? <br />
                Let’s connect now!
              </span>
            </div>
          }
          arrow
          placement="top-start"
        >
          <Image
            placeholder={`data:image/svg+xml;base64,${ImageToBase64(
              LoaderShimmer(700, 475)
            )}`}
            src={agentProfile?.profilePic || '/assets/Images/user_icon.png'}
            alt={agentProfile?.firstName[0] || 'U'}
            width={25}
            height={25}
          />
        </Tooltip>
        <section>
          <CheckCircleIcon />
        </section>
        <span>
          {agentProfile?.firstName} {agentProfile?.lastName} <br />
          <span>{agentProfile.RealEstateName}</span>
        </span>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            cursor: 'pointer',
          }}
          onClick={() =>
            window.open(
              `tel:${
                agentProfile?.phoneNumber ||
                ''.replace('-', '').replaceAll(' ', '')
              }`,
              '_self'
            )
          }
        >
          <PhoneIconSvg width={18} height={18} color="#d12d34" />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open(
              `https://wa.me/${agentProfile?.phoneNumber.replaceAll(
                '-',
                ''
              )}?text=Hello  ${agentProfile?.firstName}, %0a%0a
    I understand you are specialized in ${
      data.location
    }, as I found your details on the brokerterminal.com portal. I need some information regarding a ${
                data.bed
              }  ${data.project}, ${
                data.location
              }, and I have some questions to ask. Can you help?`,
              '_self'
            );
          }}
        >
          <WhatsappIconSvg width={18} height={18} color="#d12d34" />
        </div>
      </div>
    </div>
  );
};

export default HotProjectCard;
