import { DeveloperInfo } from '@/Components/Home/HotDevelopers';
import CheckCrossIconSvg from '@/Components/SVG/CheckCrossIconSvg';
import SlideRightSvg from '@/Components/SVG/SlideRightSvg';
import styles from '@/styles/Cards/Developer/hotdeveloper.module.scss';
import {
  CalculatePercentChange,
  NumberFormat,
  convertNumberToPrice,
} from '@/utils';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
interface developerData {
  data: DeveloperInfo;
}
const HotDeveloperCard = ({ data }: developerData) => {
  const router = useRouter();
  const [percentTxnChange, setpercentTxnChange] = useState<null | string>(null);
  const [percentVolumeChange, setpercentVolumeChange] = useState<null | string>(
    null
  );
  useEffect(() => {
    if (data) {
      setpercentTxnChange(
        CalculatePercentChange(
          data.rankdata.prevYearTransactions,
          data.rankdata.totalTxn
        )
      );
      setpercentVolumeChange(
        CalculatePercentChange(
          data.rankdata.previousTotalVolume,
          data.rankdata.totalSales
        )
      );
    }

    return () => {
      setpercentTxnChange(null);
      setpercentVolumeChange(null);
    };
  }, [data]);

  return (
    <div className={styles.cardContainer}>
      <header className={styles.cardContainer_head}>
        <Image
          src={data.overviewdata.developerImage}
          placeholder={`data:image/svg+xml;base64,${ImageToBase64(
            LoaderShimmer(700, 475)
          )}`}
          height={100}
          width={100}
          alt={data._id}
        />
        <aside>
          <span>#{data.rankdata.rank}</span>
        </aside>
      </header>
      <section></section>
      <main className={styles.cardContainer_main}>
        <h3>{data._id}</h3>
        <span>
          <CheckCrossIconSvg /> {NumberFormat(data.rankdata.totalProjects)}{' '}
          Projects
        </span>
      </main>
      <section></section>
      <main className={styles.cardContainer_mains}>
        <div className={styles.cardContainer_mains_left}>
          <p>Sales Volume 2023 </p>
          <span>
            {data.rankdata.totalTxn}{' '}
            {percentTxnChange?.includes('+') || percentTxnChange === '-0%' ? (
              <span style={{ color: '#36744f' }}>
                <div>
                  <ArrowUpwardIcon sx={{ color: '#36744F', height: '18px' }} />
                </div>
                {percentTxnChange === '-0%' ? '0%' : percentTxnChange}
              </span>
            ) : (
              <span style={{ color: '#d12d34' }}>
                <div style={{ transform: 'rotate(180deg)' }}>
                  <ArrowUpwardIcon sx={{ color: '#d12d34', height: '18px' }} />
                </div>
                {percentTxnChange === '-0%' ? '0%' : percentTxnChange}
              </span>
            )}
            vs 2022
          </span>
        </div>
        <section></section>
        <div className={styles.cardContainer_mains_right}>
          <p>Sales Value 2023 </p>
          <span>
            {convertNumberToPrice(data.rankdata.totalSales).replace('AED', '')}{' '}
            {percentVolumeChange?.includes('+') ||
            percentVolumeChange === '-0%' ? (
              <span style={{ color: '#36744f' }}>
                <div>
                  <ArrowUpwardIcon sx={{ color: '#36744F', height: '18px' }} />
                </div>
                {percentVolumeChange === '-0%' ? '0%' : percentVolumeChange}
              </span>
            ) : (
              <span style={{ color: '#d12d34' }}>
                <div style={{ transform: 'rotate(180deg)' }}>
                  <ArrowUpwardIcon sx={{ color: '#d12d34', height: '18px' }} />
                </div>
                {percentVolumeChange === '-0%' ? '0%' : percentVolumeChange}
              </span>
            )}
            vs 2022
          </span>
        </div>
      </main>

      <footer
        className={styles.cardContainer_footer}
        onClick={() => router.push(`projects?developer=${data._id}`)}
      >
        <span>View all Projects by Emaar ({data.rankdata.totalProjects})</span>
        <div>
          <SlideRightSvg color2="#d12d34" color="#fff" width={26} height={26} />{' '}
        </div>
      </footer>
    </div>
  );
};

export default HotDeveloperCard;
