import api from '@/services/api';
import styles from '@/styles/Main/Search/search.module.scss';
import { useEffect, useState } from 'react';

interface RentDropDownProps {
  searchKeyword: string;
  showDropdown?: boolean;
  setSearchKeyword: (keyword: string) => void;
  setShowDropdown: (keyword: boolean) => void;
  searchKeywordType?: string;
  setSearchKeyboardType: (keyword: string) => void;
}
const RentDropDown = ({
  searchKeyword,
  setSearchKeyword,

  setShowDropdown,

  setSearchKeyboardType,
}: RentDropDownProps) => {
  // const {
  //   data,
  //   error: graphDataError,
  //   isLoading: graphDataIsLoading,
  // } = useSWRImmutable(
  //   `/transaction/suggestive-search-sold?value=${searchKeyword}`,
  //   fetcher
  // );
  const [data, setdata] = useState<any | null>(null);

  useEffect(() => {
    const handleSearch = async () => {
      // Perform your API request here using the searchKeyword value
      // Only send the request if the searchKeyword has a sufficient length (e.g., greater than 2 characters)

      const responseData = await api.get(
        `/rentaltxn/suggestive-search-sold?value=`
      );
      setdata(responseData.data);
    };

    handleSearch();

    return () => {
      setdata([]);
    };
  }, []);

  return data && data.project && searchKeyword.length > 2 ? (
    <div className={styles.dropDownContainer}>
      {data.locations.length > 0 &&
        data.locations
          .filter((tdx: string) =>
            tdx.toUpperCase().includes(searchKeyword.toUpperCase())
          )
          .map((item: any, index: number) => (
            <div
              className={styles.dropDownContainer_item}
              key={item._id || index}
              onClick={() => {
                setSearchKeyboardType('l');
                setShowDropdown(false);
                setSearchKeyword(item);
              }}
            >
              {' '}
              <span>
                {item.split(' ').map((each: string) =>
                  each.toUpperCase().includes(searchKeyword.toUpperCase()) ||
                  (searchKeyword.toUpperCase().includes(each.toUpperCase()) &&
                    each.length > 2) ? (
                    <span
                      key={each + String(index)}
                      style={{
                        // backgroundColor: 'var(--primary)',
                        color: 'white',
                      }}
                    >
                      {each}{' '}
                    </span>
                  ) : (
                    <span key={each + String(index)}>{each} </span>
                  )
                )}
              </span>
            </div>
          ))}
      {data.project.length > 0 &&
        data.project
          .filter((tdx: string) =>
            tdx.toUpperCase().includes(searchKeyword.toUpperCase())
          )
          .map((item: any, index: number) => (
            <div
              className={styles.dropDownContainer_item}
              key={item._id || index}
              onClick={() => {
                setSearchKeyboardType('p');
                setShowDropdown(false);
                setSearchKeyword(item);
              }}
            >
              {' '}
              <span>
                {item.split(' ').map((each: String) =>
                  each.toUpperCase().includes(searchKeyword.toUpperCase()) ||
                  (searchKeyword.toUpperCase().includes(each.toUpperCase()) &&
                    each.length > 2) ? (
                    <span
                      key={each + String(index)}
                      style={{
                        // backgroundColor: 'var(--primary)',
                        color: 'white',
                      }}
                    >
                      {each}{' '}
                    </span>
                  ) : (
                    <span key={each + String(index)}>{each} </span>
                  )
                )}
              </span>
            </div>
          ))}
    </div>
  ) : null;
};
export default RentDropDown;
