import { fetcher } from '@/services/fetcher';
import styles from '@/styles/SideComp/sidecomponents.module.scss';
import { NumberFormat } from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as React from 'react';
import useSWR from 'swr';
import DotLoader from '../Loaders/DotLoader';

interface sideGolden {
  OpenAllLocations: boolean;
  setOpenAllLocations: (open: boolean) => void;
  categoryType: string;
}
export default function SideLocationsOnly({
  OpenAllLocations,
  setOpenAllLocations,
  categoryType,
}: sideGolden) {
  const { data, isLoading } = useSWR(
    `/transaction/locations-all?location=${categoryType}`,
    fetcher
  );
  const { isMobile } = React.useContext(MobileContext);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpenAllLocations(open);
    };
  const router = useRouter();

  const list = () => (
    <Box
      sx={{
        width: isMobile ? '100%' : '400px',
        padding: '20px',
        overflowX: 'hidden',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={styles.sidebarMain_heading}>
        <span style={{ fontWeight: 'bold' }}>Localities In Dubai</span>{' '}
        <KeyboardBackspaceIcon onClick={() => toggleDrawer(false)} />
        <ClearIcon onClick={() => toggleDrawer(false)} />
      </div>
      <Divider />
      <List>
        {(!data || isLoading) && <DotLoader />}
        {data &&
          data.map((location: any, index: number) => (
            <div key={location._id} className={styles.sidebarMain_lcards}>
              <div
                style={{ width: '100px', height: '100px' }}
                className={styles.sidebarMain_lcards_left}
              >
                <Image
                  width={700}
                  height={700}
                  src={
                    location.overviewdata &&
                    location.overviewdata.locationImage[0] &&
                    location.overviewdata.locationImage[0] !== ''
                      ? location.overviewdata.locationImage[
                          Math.floor(
                            Math.random() *
                              location.overviewdata?.locationImage.length
                          )
                        ]
                      : 'https://static.99acres.com/images/locationREI/defaultSquareLocality.png'
                  }
                  alt={location._id}
                />
              </div>
              <div className={styles.sidebarMain_lcards_right}>
                <h2>{location._id}</h2>
                <span>
                  {' '}
                  {location.projects && (
                    <>({NumberFormat(location.projects)})</>
                  )}
                </span>
                <p
                  onClick={() =>
                    router.push(`/projects?keyword=${location._id}`)
                  }
                >
                  View All Projects{' '}
                </p>
              </div>
            </div>
          ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div className={styles.sidebarMain}>
      <Drawer
        anchor={'right'}
        open={OpenAllLocations}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: '20002' }}
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '400px',
            zIndex: '20001',
            borderTopLeftRadius: isMobile ? '' : '20px',
            borderBottomLeftRadius: isMobile ? '' : '20px',
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
