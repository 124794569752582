import { fetcher } from '@/services/fetcher';
import styles from '@/styles/Home/trendingLocaties.module.scss';
import { customSortBedrooms } from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import { useContext, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import 'swiper/css';

import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import Arrowback from '../Buttons/ArrowBack';
import ArrowForward from '../Buttons/ArrowForward';
import HotProjectCard from '../Cards/Project/HotProjectCard';
import BtSelectField from '../Fields/BtSelectField';
import DotLoader from '../Loaders/DotLoader';
import DoorIconSvg from '../SVG/DoorIconSvg';
import HomeIconSvg from '../SVG/HomeIconSvg';
import SideLocationsOnly from '../SideComponents/SideLocationsOnly';

export interface ProjectData {
  users: {
    _id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePic: string;
    RealEstateName: string;
  };
  Developer: string;
  minPrice: number;
  maxPrice: number;
  averageSize: number;
  Type: string;
  totalTransactions: number;
  prevYearTransactions: number;
  project: string;
  location: string;
  bed: string;
  rankdeveloper: {
    rank: number;
  };
  rentalMinPrice: number;
  rentalMaxPrice: number;
  rentalTotalTransactions: number;
  rentalPrevYearTransactions: number;
  overview: {
    completionDate: string;
    status: string;
    developer: string;
    developerImage: string;
    locationImage: string[];
    projectImage: string[];
  };
}

const HotProjects = () => {
  const [selectedCategoryType, setselectedCategoryType] = useState('');
  const [selectedBed, setselectedBed] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const [selectedCompletion, setselectedCompletion] = useState('All');
  const { data: allBedrooms, isLoading: isLoadinBedroom } = useSWRImmutable(
    `/dubai/get-all-bedrooms`,
    fetcher
  );
  const { data: allTypes, isLoading: isLoadingTypes } = useSWRImmutable(
    `/dubai/get-all-types`,
    fetcher
  );
  const { data: hotProjects, isLoading } = useSWR(
    `/dubai/hot-projects?bed=${selectedBed ? selectedBed : ''}&type=${
      selectedType ? selectedType : ''
    }&completion=${selectedCompletion ? selectedCompletion : ''}`,
    fetcher
  );

  const sliderSettings = {
    360: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    320: {
      slidesPerView: 1.1,
      spaceBetween: 1,
    },

    375: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    410: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    680: {
      slidesPerView: 2.1,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
  };
  const [OpenAllLocations, setOpenAllLocations] = useState(false);
  const swiperRef = useRef<SwiperCore>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const handleSlideChange = () => {
    const swiper = swiperRef.current;
    if (swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };
  const cardsMap =
    hotProjects &&
    hotProjects.map((item: ProjectData, index: number) => (
      <SwiperSlide key={item.project + String(index)}>
        <HotProjectCard data={item} />
      </SwiperSlide>
    ));
  const { isMobile } = useContext(MobileContext);
  return (
    <div className={styles.hotContainer}>
      <header className={styles.hotContainer_header}>
        <h1>
          Top Projects in the Market: <span>Based on 1 Year Sales Record</span>
        </h1>
      </header>
      <main className={styles.hotContainer_main}>
        <div className={styles.hotContainer_main_head}>
          <aside>
            {' '}
            <BtSelectField
              options={
                allBedrooms
                  ? customSortBedrooms(
                      allBedrooms.filter((d: String) => d)
                    ).map((ty: string) => ({ value: ty, label: ty }))
                  : []
              }
              isSearchable={false}
              icon={<DoorIconSvg color="var(--primary)" />}
              value={
                selectedBed
                  ? { value: selectedBed, label: selectedBed }
                  : { value: null, label: null }
              }
              setselectValue={(e) =>
                //@ts-ignore
                setselectedBed(e.value)
              }
              label="Number of Rooms"
            />
          </aside>
          <aside>
            <BtSelectField
              options={
                allTypes
                  ? allTypes.map((ty: string) => ({ value: ty, label: ty }))
                  : []
              }
              isSearchable={false}
              icon={<HomeIconSvg color="var(--primary)" />}
              value={
                selectedType
                  ? { value: selectedType, label: selectedType }
                  : { value: null, label: null }
              }
              setselectValue={(e) =>
                //@ts-ignore
                setselectedType(e.value)
              }
              label="Property Type"
            />
          </aside>
          <section className={styles.hotContainer_main_head_section}>
            {['All', 'Completed', 'Ongoing'].map((l: string) => (
              <button
                key={l}
                onClick={() => setselectedCompletion(l)}
                className={
                  styles[
                    selectedCompletion === l
                      ? 'hotContainer_main_head_section_sbtn'
                      : 'hotContainer_main_head_section_btn'
                  ]
                }
              >
                {l} {l === 'All' ? '' : 'Project'}
              </button>
            ))}
          </section>
        </div>
        <div className={styles.hotContainer_main_contents}>
          {(!hotProjects || isLoading) && <DotLoader />}
          <div
            style={{
              position: 'relative',
              paddingBottom: isMobile ? '10px' : '60px',
            }}
          >
            {!isMobile && (
              <Arrowback
                onClick={() => swiperRef.current?.slidePrev()}
                state={isBeginning ? 'disabled' : 'active'}
              />
            )}

            {hotProjects && (
              <Swiper
                breakpoints={sliderSettings}
                slidesPerView="auto"
                className="swiperr-bot"
                speed={800}
                onBeforeInit={(swiper) => {
                  //@ts-ignore
                  swiperRef.current = swiper;
                }}
                onSlideChange={handleSlideChange}
              >
                {cardsMap}
              </Swiper>
            )}
            {!isMobile && (
              <ArrowForward
                onClick={() => swiperRef.current?.slideNext()}
                state={isEnd ? 'disabled' : 'active'}
              />
            )}
          </div>
          {/* <button
            className={styles.hotContainer_main_contents_abutton}
            onClick={() => setOpenAllLocations(true)}
          >
            View all locations in Dubai
          </button> */}
        </div>
      </main>
      {OpenAllLocations && (
        <SideLocationsOnly
          OpenAllLocations={OpenAllLocations}
          setOpenAllLocations={setOpenAllLocations}
          categoryType={selectedCategoryType}
        />
      )}
    </div>
  );
};

export default HotProjects;
