import TerminalButton from '@/Components/Buttons/TerminalButton';
import BtSelectField from '@/Components/Fields/BtSelectField';
import { optionsInt } from '@/Components/Home/BHKChoice';
import ApartmentSvgIcon from '@/Components/SVG/ApartmentSvgIcon';
import { fetcher } from '@/services/fetcher';
import styles from '@/styles/Modals/dialog.module.scss';
import { slugify } from '@/utils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import useSWRImmutable from 'swr/immutable';
const UserFeedBackUnit = ({
  project,
  open,
  setOpen,
}: {
  project: string;
  open: boolean;
  setOpen: (op: boolean) => void;
}) => {
  const [selectedUnit, setselectedUnit] = useState<optionsInt>({
    value: null,
    label: null,
  });

  const { data: projectUnits, isLoading } = useSWRImmutable(
    `/transaction/project-units?project=${project}`,
    fetcher
  );
  const handleClose = () => {
    setOpen(false);
  };
  const router = useRouter();
  const handleSubmit = () => {
    handleClose();
    router.push(`/dubai/unit-trends/${slugify(project)}/${selectedUnit.value}`);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        padding: '0 !important',
        zIndex: '1000 !important',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            background: '#fff',
            // background: "#000",
            color: '#000',
            borderRadius: '24px',
            maxWidth: '500px',
            padding: '0', // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        Please Select A Unit in{' '}
        <b style={{ color: 'var(--primary)' }}> {project} </b>
      </DialogTitle>
      <DialogContent className={styles.enquiryContainer}>
        <div className={styles.enquiryContainer_options}>
          <BtSelectField
            value={selectedUnit}
            setselectValue={setselectedUnit}
            icon={<ApartmentSvgIcon />}
            label="Units"
            options={
              projectUnits
                ? projectUnits.map((pu: string) => ({ value: pu, label: pu }))
                : []
            }
          />
        </div>
        <div className={styles.enquiryContainer_wish}>
          <span>Select The Unit You wish to see History.</span>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', display: 'flex' }}>
        <TerminalButton position="gg" onClick={handleSubmit} label="Search" />
      </DialogActions>
    </Dialog>
  );
};

export default UserFeedBackUnit;
